<template>
  <div>
    <ol>
      <li>
        <p>Pastikan akun Rekan Alumni sudah tereverifikasi dan memenuhi syarat untuk mengikuti e-vote Pemilihan Langsung dengan klik 'Cek Status' di bawah halaman utama PEMILA ILUNI UI 2022 di UI Connect.</p>
        <img
          class="img-size-mobile"
          :src="require('@/assets/images/guides/image2.png')"
        >
      </li>
      <li>
        <p>Jika muncul informasi bahwa pendidikan Anda belum terverifikasi, Hubungi tim User Support UI Connect melalui email halo@uiconnect.id dengan menginformaskan email yang digunakan pada akun UI Connect Rekan Alumni.</p>
        <img
          class="img-size-mobile"
          :src="require('@/assets/images/guides/image3.png')"
        >
        <br>
        <br>
        <img
          class="img-size-mobile"
          :src="require('@/assets/images/guides/image4.png')"
        >

      </li>
    </ol>
  </div>
</template>

<style scoped>
 /* width: 360px; */
  .img-size-mobile {
    width: 80%
  }

  li {
    margin-bottom: 16px;
  }
  p {
    color: #1b1b1b;
  }

</style>

<script>

export default {
  components: {

  },
}
</script>
