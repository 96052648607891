<template>
  <div>
    <ol>
      <li>
        <p>Klik Tombol “LIhat Rekapitulasi” pada bagian bawah halaman utama PEMILA. Halaman rekapitulasi hasil hanya bisa diakses pada saat masa E-Vote telah berakhir dan hasil telah dibuka aksesnya oleh panitia PEMILA.</p>
        <img
          class="img-size-mobile"
          :src="require('@/assets/images/guides/image6.png')"
        >
      </li>
      <li>
        <p>Pada halaman rekapitulasi Rekan Alumni dapat melihat total suara masuk secara kesuluruhan dan total suara masuk per kandidat. Anda juga dapat melihat hasil ketetapan kandidat terpilih menjadi Ketua Umum ILUNI UI Periode 2022 - 2025.</p>
        <img
          class="img-size-mobile"
          :src="require('@/assets/images/guides/image7.png')"
        >

        <br>
        <br>

        <img
          class="img-size-mobile"
          :src="require('@/assets/images/guides/image8.png')"
        >
      </li>
    </ol>
  </div>
</template>

<style scoped>
 /* width: 360px; */
  .img-size-mobile {
    width: 80%
  }

  li {
    margin-bottom: 16px;
  }
  p {
    color: #1b1b1b;
  }

</style>

<script>

export default {
  components: {

  },
}
</script>
