var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"wrapper-mobile-size"}},[_c('div',{staticClass:"mobile-size"},[_c('div',{staticClass:"guides"},[_c('Item',{attrs:{"icon":_vm.guides.cekStatus.icon,"show":_vm.guides.cekStatus.show,"title":_vm.guides.cekStatus.title},on:{"toggle":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.toggle.apply(void 0, [ 'cekStatus' ].concat( argsArray ))}}},[_c('CekStatus')],1),_c('Item',{attrs:{"icon":_vm.guides.caraEvote.icon,"show":_vm.guides.caraEvote.show,"title":_vm.guides.caraEvote.title},on:{"toggle":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.toggle.apply(void 0, [ 'caraEvote' ].concat( argsArray ))}}},[_c('CaraEvote')],1),_c('Item',{attrs:{"icon":_vm.guides.lihatRekapitulasi.icon,"show":_vm.guides.lihatRekapitulasi.show,"title":_vm.guides.lihatRekapitulasi.title},on:{"toggle":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.toggle.apply(void 0, [ 'lihatRekapitulasi' ].concat( argsArray ))}}},[_c('LihatRekapitulasi')],1),_c('Item',{attrs:{"icon":_vm.guides.faq.icon,"show":_vm.guides.faq.show,"title":_vm.guides.faq.title},on:{"toggle":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.toggle.apply(void 0, [ 'faq' ].concat( argsArray ))}}},[_c('Faq')],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }