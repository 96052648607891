<template>
  <div
    id="wrapper-mobile-size"
  >
    <div class="mobile-size">
      <div class="guides">
        <Item
          :icon="guides.cekStatus.icon"
          :show="guides.cekStatus.show"
          :title="guides.cekStatus.title"
          @toggle="toggle('cekStatus', ...arguments)"
        >
          <CekStatus />
        </Item>
        <Item
          :icon="guides.caraEvote.icon"
          :show="guides.caraEvote.show"
          :title="guides.caraEvote.title"
          @toggle="toggle('caraEvote', ...arguments)"
        >
          <CaraEvote />
        </Item>
        <Item
          :icon="guides.lihatRekapitulasi.icon"
          :show="guides.lihatRekapitulasi.show"
          :title="guides.lihatRekapitulasi.title"
          @toggle="toggle('lihatRekapitulasi', ...arguments)"
        >
          <LihatRekapitulasi />
        </Item>
        <Item
          :icon="guides.faq.icon"
          :show="guides.faq.show"
          :title="guides.faq.title"
          @toggle="toggle('faq', ...arguments)"
        >
          <Faq />
        </Item>
      </div>

    </div>
  </div>
</template>

<style scoped>
  @media (max-width: 640px) {
    .mobile-size {
      width: 100vw !important;
    }

    /* .mobile-size section .candidate button {
      right: 0 !important;
    } */
  }

  #wrapper-mobile-size {
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: #E5E5E5;
  }

  .mobile-size {
    width: 360px;
    height: 100vh;
    background-color: #F9F9F9;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  .guides {
    margin-bottom: 4.75rem;
  }

  .guide {
    display: flex;
    flex-direction: row;
    height: 56px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 16px;
    border-bottom: 1px solid #E0E0E0;
  }

  .guide-title-name {
    margin-left: 12px;
    font-weight: 500;
    font-size: 14px;
  }

  .guide-title {
    display: flex;
    flex-direction: row;
  }

  .guide-title img {
    height: 24px;
    width: 24px;
  }
</style>

<script>
import Faq from './components/Faq.vue'
import CekStatus from './components/CekStatus.vue'
import CaraEvote from './components/CaraEvote.vue'
import LihatRekapitulasi from './components/LihatRekapitulasi.vue'
import Item from './Item.vue'

export default {
  components: {
    Item,
    CekStatus,
    CaraEvote,
    LihatRekapitulasi,
    Faq,
  },
  data() {
    return {
      guides: {
        cekStatus: {
          icon: 'people',
          title: 'Cek Status Keikutsertaan',
          show: false,
        },
        caraEvote: {
          icon: 'vote',
          title: 'Cara Melakukan E-Vote',
          show: false,
        },
        lihatRekapitulasi: {
          icon: 'rekapitulasi',
          title: 'Lihat Rekapilutasi E-Vote',
          show: false,
        },
        faq: {
          icon: 'faq',
          title: 'FAQ',
          show: false,
        },
      },
    }
  },
  methods: {
    toggle(key, value) {
      const keys = Object.keys(this.guides)
      keys.forEach(keyGuide => {
        if (keyGuide !== key) {
          this.guides[keyGuide].show = false
        }
      })
      this.guides[key].show = value
      document.getElementsByClassName('mobile-size')[0].scrollTo({ top: 0, behavior: 'smooth' })
    },
  },
}
</script>
