<template>
  <div>
    <ol>
      <li>
        <p>Salah satu data pendidikan saya belum terverifikasi. Apakah saya bisa mengikuti e-vote?</p>
        <p>Rekan Alumni dapat mengikuti e-vote Pemilihan Langsung Ketua ILUNI UI 2022-2025 dengan persyaratan memiliki salah satu data pendidikan yang terverifikasi di akun UI Connect selama masih dalam batas waktu yang ditentukan.</p>
      </li>

      <li>
        <p>Apakah saya bisa menggunakan nomor handphone yang sudah digunakan sebelumnya untuk melakukan request kode OTP pada akun UI Connect Rekan Alumni saya yang lain?
        </p>
        <p>UI Connect membatasi satu vote hanya bisa dilakukan melalui satu akun dan menggunakan satu nomor handphone. Akun UI Connect dan nomor handphone yang sudah melakukan e-vote sebelumnya tidak dapat digunakan kembali untuk memilih.</p>
      </li>

      <li>
        <p>Apakah biaya pengiriman kode OTP melalui SMS dibebankan ke penerima SMS?

        </p>
        <p>Biaya pengiriman kode OTP SMS bersifat gratis dan tidak ditanggung oleh penerima SMS.</p>
      </li>
    </ol>
  </div>
</template>

<style scoped>
 /* width: 360px; */
  .img-size-mobile {
    width: 80%
  }

  li {
    margin-bottom: 16px;
  }
  p {
    color: #1b1b1b;
  }

</style>

<script>

export default {
  components: {

  },
}
</script>
