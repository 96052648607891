<template>
  <div>
    <div
      class="guide"
      :class="show ? 'bg-yellow' : 'bg-white'"
      :style="!show ? 'border-bottom: 1px solid #E0E0E0;' : 'border-bottom: none;'"
      @click="toggle"
    >
      <div
        class="guide-title"
      >
        <div>
          <img :src="require(`@/assets/images/icons/${icon}.png`)">
        </div>
        <div class="guide-title-name">
          {{ title }}
        </div>
      </div>
      <div>
        <feather-icon
          :icon="show ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          size="18"
        />
      </div>
    </div>
    <div
      v-if="show"
      class="guide-content"
    >
      <slot />
    </div>
  </div>
</template>

<style scoped>
  .guide {
    display: flex;
    flex-direction: row;
    height: 56px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 16px;
    border-bottom: 1px solid #E0E0E0;
  }

  .guide-title-name {
    margin-left: 12px;
    font-weight: 500;
    font-size: 14px;
  }

  .guide-title {
    display: flex;
    flex-direction: row;
  }

  .guide-title img {
    height: 24px;
    width: 24px;

  }

  .guide-content {
    padding: 16px;
    background-color: #FFC7001F;
  }

  .bg-yellow {
    background-color: #FFC7001F;
  }

  .bg-white {
    background: #fff;
  }
</style>

<script>

export default {
  props: {
    show: Boolean,
    icon: {
      type: String,
      default: 'people',
    },
    title: {
      type: String,
      default: 'Cek Status Keikutsertaan',
    },
  },
  data() {
    return {
      // show: true,
    }
  },
  methods: {
    toggle() {
      this.$emit('toggle', !this.show)
    },
  },
}
</script>
